import React from "react";
import ReactDOM from "react-dom";
import { SocialIcon } from "react-social-icons";
import "./contact.css";

function Contact(props) {
  return (
    <div className="container-fluid">
      {/* <section className="sect pl-5 mt-5 pt-5 col-12 d-flex"> */}
      <center>
        <div className="d-inline-block">
          <div className="">
            <h5 className="">Address</h5>
            <p>Silver Spring, MD 20910, USA</p>
          </div>

          <div className="">
            <h5 className="">Email</h5>
            <p>hushay2@gmail.com</p>
          </div>
          <div className="">
            <h5 className="">Phone</h5>
            <p>301-275-9370</p>
          </div>
        </div>
        <div className="">
          <div className="">
            <div>
              <SocialIcon
                url="https://linkedin.com/in/hussein-mohammed-7ba78a24a"
                className="lin"
              />
            </div>
            <div>
              <SocialIcon url="https://github.com/hussein-ghub" />
            </div>
          </div>
        </div>
      </center>
      {/* </section> */}
    </div>
  );
}

export default Contact;
