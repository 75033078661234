import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
// import Home from "./Components/Home/Home";
import About from "./Components/About/About";
import Header from "./Components/Header/Header";
// import "./Header.css";
// import Home from "./Components/Home/Home";
import Banner from "./Components/Banner/Banner";
// import Resume from "./Components/Resume/Resume";
import Portfolio from "./Components/Portfolio/Portfolio";
import "bootstrap/dist/css/bootstrap.min.css";
import Contact from "./Components/Contact/Contact";
// import SinglePortfolio from "./Components/SinglePortfolio/SinglePortfolio";
// import "./fontawesome.css";
import "./mystyle.css";
import Service from "./Components/Service/Service";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route
          path="/home"
          element={
            <>
              <Banner />
              <About />
              <Service />
              <Portfolio />
              <Contact />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <Banner />
              <About />
              <Service />
              <Portfolio />
              <Contact />
            </>
          }
        />
        <Route path="/About" element={<About />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="Portfolio" element={<SinglePortfolio />} /> */}
      </Routes>
    </div>
  );
}

export default App;
