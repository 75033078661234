import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Header.css";
import Logo from "../../Images/Hussein-1-modified.png";
// import Circle from "../../Images/circle-profile.png";

function Header() {
  return (
    <Navbar
      className="bgco"
      fixed="top"
      collapseOnSelect
      variant="dark"
      expand="lg"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />{" "}
      <Navbar.Brand href="/">
        <img className="logo" src={Logo} alt="" width="70" height="70" />
      </Navbar.Brand>
      <Navbar.Collapse id="">
        <Nav className="nav-justified w-100 nav-fill">
          <Nav.Link className="nav-link" href="/home">
            Home
          </Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
          <Nav.Link href="/portfolio">Portfolio</Nav.Link>
          <Nav.Link href="/service">Service</Nav.Link>
          <Nav.Link href="/contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      {/* <img className="Cir" src={Circle} alt="" /> */}
    </Navbar>
  );
}

export default Header;
