import React from "react";
import { Link } from "react-router-dom";
import "./Banner.css";
import Circle from "../../Images/circle-profile.png";
import backgroundImage from "../../Images/desktop.jpg";

function Banner() {
  return (
    <div>
      <div
        className="baground"
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container-fluid">
          <div className="d-flex">
            <img className="Cir pt-4 mt-5" src={Circle} alt="" />
            <div className="row full-screen align-items-center justify-content-center">
              <div className="col-lg-9">
                <div className="text-center">
                  <h2 className="text-danger pb-1 mb-4 pl-4">Hello!</h2>
                  <div className="pro">
                    <h2 className="text-dark">I am Hussein</h2>
                    <h2 className="font-weight-bold pb-5 text-dark">
                      A Full Stack Devloper{" "}
                    </h2>
                  </div>
                  <div className="pb-3 mb-2">
                    <Link
                      className="m-btn m-btn-lg m-btn-radius m-btn-theme"
                      to="/About"
                    >
                      Let's talk New
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
