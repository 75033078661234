import React from "react";

function Service() {
  return (
    <div>
      <div className="container-fluid">
        <center>
          <h2 className="pb-5">Work</h2>
          <div className="Work">
            <div>
              <div className="">
                <h6 className="font-w-600">BX4 ENGINEERING & TECHNOLOGY LLC</h6>
                <p className="font-small ">
                  <i> 2021 - Present</i>
                  <br />
                  <i> Full Stack Developer</i>
                </p>
                <p className="">
                  {" "}
                  Used JavaScript, ReactJS, NodeJS and other frameworks to
                  develop app solutions.
                  <br />
                  Developed efficient and maintainable software according to
                  business objectives and needs of client.
                </p>
              </div>
              <div>
                <h6 className="font-w-600 pt-5">OSSE</h6>
                <p className="font-medium ">
                  <i>2018 - Present</i>
                </p>
                <p className="">
                  DC - OSSE <br />
                  MVO – Part time, Working in District of Colombia Governement,
                  Student Transportion Service.
                </p>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
}

export default Service;
