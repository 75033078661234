// import React from "react";
import Apple from "../../Images/apple.jpeg";
import Amazon from "../../Images/amazon.jpeg";
import Evangadi from "../../Images/Evangadi.png";
import Netflix from "../../Images/netflix.jpeg";
import AppleReact from "../../Images/apple.jpeg";
import Zoom from "../../Images/zoom.jpeg";
import { Link } from "react-router-dom";
import "./Portfolio.css";
// function Portfolio() {
// return (
// <div>
/* <div className="grid-container">
        <div className="grid-item">1</div>
        <div className="grid-item">2</div>
        <div className="grid-item">3</div>
        <div className="grid-item">4</div>
        <div className="grid-item">5</div>
        <div className="grid-item">6</div>
        <div className="grid-item">7</div>
        <div className="grid-item">8</div>
        <div className="grid-item">9</div>
      </div> */
// <h1>hello</h1>
// </div>
// );
// }

// export default Portfolio();
import React from "react";

function Portfolio() {
  return (
    <div className="All">
      <p className="text-center fw-600">
        <span className="text-danger"> My</span> Personal
        <span className="text-danger"> Projects</span>
      </p>
      <div className="grid-container">
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={AppleReact} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="https://tourmaline-hamster-b19fed.netlify.app/">
                  Apple website build on react
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={Amazon} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="https://e-huss.web.app/">Amazone build on react</a>
              </h5>
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={Evangadi} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="https://visionary-taiyaki-4adb1c.netlify.app/">
                  Evangadi Net build on react
                </a>
              </h5>
            </div>
          </div>
        </div>{" "}
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={Apple} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="https://gorgeous-queijadas-336287.netlify.app/">
                  Apple website build on bootstrap
                </a>
              </h5>
            </div>
          </div>
        </div>{" "}
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={Netflix} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="https://bespoke-muffin-37a2d5.netlify.app/">
                  Netflix website build on react
                </a>
              </h5>
            </div>
          </div>
        </div>
        <div className="grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-img">
              <img src={Zoom} alt="" />
            </div>
            <div className="portfolio-info">
              <Link to="" className="gallery-link">
                <i></i>
              </Link>
              <h5>
                <a href="/">Zoom build on react</a>
              </h5>
            </div>
          </div>
        </div>
        {/* <div className="grid-item">7</div>
        <div className="grid-item">8</div>
        <div className="grid-item">9</div> */}
      </div>
    </div>
  );
}

export default Portfolio;
