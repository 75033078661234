import React from "react";
import View from "../../Images/View.jpg";
import "./About.css";
import { Link } from "react-router-dom";

import Resume from "./RESU1_email.pdf";

function About() {
  return (
    // <section id="abou">
    <div className="main container">
      <div className="view">
        <img src={View} alt="" />
      </div>

      <div className="text-about">
        <h2 className="dark-color text-center">
          About <span className="text-danger">Me</span>
        </h2>

        <h5 className="hello">Hello! i'm Hussein Mohammed</h5>
        <div className="font-weight-light">
          {" "}
          <h6 className="">
            Motivated and dynamic Web Developer, with a passion for developing
            scalable web applications and working across the full stack. I am
            looking to join forces with front-end and back-end Developers to
            continue and grow my skill set while contributing to the positive
            outcome of making people happier.
          </h6>
          <h6>
            with a keen focus and experience in web application development and
            web layout design, with a strong work ethic and innate ability to
            adapt in ever-changing and challenging environments.
          </h6>
        </div>
        <div className="p-25px-t">
          <Link className="m-btn-theme m-btn m-btn-radius" to="/contact">
            contact me
          </Link>
          <a href={Resume} download="MyResume">
            <button className="m-btn-theme m-btn m-btn-radius m-5px-l">
              download cv
            </button>
          </a>
        </div>
      </div>
    </div>
    // </section>
  );
}

export default About;
